.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.header {
    margin: auto;
    height: 50px;
    line-height: 50px;
}

// .main {
//     display: grid;
//     grid-template-columns: repeat(8, 1fr);
//     grid-template-rows: repeat(8, 1fr);
//     grid-gap: 16px;
//     margin: 16px;
//     height: 100%;

//     div {
//         grid-row: span 2;
//         grid-column: span 3;

//         padding: 16px;
//         border: 2px solid var(--gray);
//         border-radius: 8px;
//     }
// }

.main {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        margin: 16px;
        height: 100%;
    
        div {
            border: 2px solid var(--gray);
            border-radius: 8px;

            h3, p {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.5rem;
                text-align: center;
                line-height: 2rem;
            }
        }
    }

.footer {
    line-height: 50px;
    font-size: 0.7rem;
    text-align: center;
    color: var(--gray);
}
