@use 'colors';

body {
    width: 100vw;
    background-color: var(--off-white);
    text-rendering: optimizeLegibility;
    color: var(--black);
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    overscroll-behavior-y: none;
    overflow: hidden;
}

@media print {
    @page {
        size: A4; /* DIN A4 standard, Europe */
        margin: 12.7mm;
    }
    html,
    body {
        width: 210mm;
        /* height: 297mm; */
        height: 282mm;
        font-size: 11px;
        background: #fff;
        overflow: visible;
    }
    body {
        padding-top: 15mm;
    }
    header,
    footer,
    aside,
    nav,
    form {
        display: none !important;
    }
}
